import React, { CSSProperties, useEffect, useState, useCallback } from 'react';
import './App.css';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Spinner from 'react-bootstrap/Spinner';
import { PluggyConnect } from 'react-pluggy-connect';

import { callCloudFunction } from './scripts/firebaseApi';

interface ResponseData {
  data: {
    accessToken?: string;
    isTester?: boolean;
  };
}

function App() {
  const [token, setToken] = useState<string>();
  const [error, setError] = useState();
  const [isConnectVisible, setIsConnectVisible] = useState(false);
  const [success, setSuccess] = useState(false);
  const [includeSandbox, setIncludeSandbox] = useState(false);

  const searchParams = new URLSearchParams(document.location.search);
  const investorId = searchParams.get('clientId');

  console.log(`investorId = ${investorId}`);
  console.log(`error = ${error}`);

  useEffect(() => {
    async function fetchToken() {
      try {
        const response = (await callCloudFunction('getInvestorAccessToken', {
          investorId,
        })) as ResponseData;
        const accessToken = response?.data?.accessToken;
        setToken(accessToken);
      } catch (error: any) {
        setError(error.message);
      }
    }
    fetchToken();
    // Fetch data every 10 minutes
    const intervalId = setInterval(fetchToken, 10 * 60 * 1000);
    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [investorId]);

  useEffect(() => {
    const isClientTest = async (id: string) => {
      const response = (await callCloudFunction('isTestInvestor', {
        investorId,
      })) as ResponseData;
      const isTester = response?.data?.isTester ?? false;
      console.log(`isTester = ${isTester}`);
      setIncludeSandbox(isTester);
      setIsConnectVisible(true);
      if (isTester) {
        callCloudFunction('copyMockDataToInvestor', { investorId });
      }
    };
    if (investorId) {
      isClientTest(investorId);
    }
  }, [investorId]);

  const onClosePopup = useCallback(() => {
    setIsConnectVisible(false);
  }, []);

  return (
    <Container style={mainContainerStyle}>
      <Spinner animation="border" variant="primary" />
      <Image
        src={require('./images/logo-white.png')}
        alt="Black Belt"
        style={logoStyle}
      />
      {investorId ? (
        <Card.Text style={textStyle}>
          {success ? 'Obrigado' : 'Aguarde um instante'}
        </Card.Text>
      ) : (
        <Card.Text style={textStyle}>
          Link incorreto<br></br>
          Fale com seu assessor
        </Card.Text>
      )}

      {token && isConnectVisible && investorId && (
        <PluggyConnect
          connectToken={token ?? ''}
          includeSandbox={includeSandbox}
          allowConnectInBackground={true}
          // products={['INVESTMENTS', 'INVESTMENTS_TRANSACTIONS']}
          onClose={onClosePopup}
          onSuccess={() => {
            setSuccess(true);
          }}
        />
      )}
    </Container>
  );
}

export default App;

const logoStyle: CSSProperties = {
  width: '70%',
};

const mainContainerStyle: CSSProperties = {
  textAlign: 'center',
  backgroundColor: '#282c34',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
};

const textStyle: CSSProperties = {
  marginTop: '20px',
  fontSize: '5vw',
};
