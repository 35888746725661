import { initializeApp } from "firebase/app";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyB7K2iEmLFdSGke1cPCFueHEl2ec3ju6kU",
  authDomain: "blackbelt-674a4.firebaseapp.com",
  projectId: "blackbelt-674a4",
  storageBucket: "blackbelt-674a4.appspot.com",
  messagingSenderId: "1024807919912",
  appId: "1:1024807919912:web:59e7f5520faf0e6c51301d"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

const region = 'southamerica-east1';
export const functions = getFunctions(app, region);