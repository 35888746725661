import params from '../constants/params.json';
import { functions } from './firebaseConfig';
import { connectFunctionsEmulator, httpsCallable } from 'firebase/functions';

const cloudFunctionCall = (functionName: string, data?: unknown) => {
  if (params.emulator.connect) {
    connectFunctionsEmulator(functions, '127.0.0.1', params.emulator.port);
  }
  const callableReference = httpsCallable(functions, functionName);
  return callableReference(data);
};

export const callCloudFunction = async (
  functionName: string,
  data?: unknown,
) => {
  const response = await cloudFunctionCall(functionName, data);
  return response.data;
};

// export const fetchHttpCloudFunction = async (url: string, options?: object) => {
//   try {
//     const data = await fetch(url, options);
//     const response = await data.json();
//     return response;
//   } catch (error: any) {
//     sendEmailToAdmin('erro em fetchHttpCloudFunction', error);
//   }
// };